<template>
  <v-container fluid>
    <v-layout row wrap justify="center">
      <v-flex xs12 md12>
        <v-card class="mx-auto mb-0 elevation-0" rounded>
          <offer-filters @resetPagination="resetPagination()" />
          <v-divider class="mx-5"></v-divider>
          <v-card-text>
            <data-table
              ref="table"
              :headers="headers"
              :items="Offers.state.items"
              :items-per-page="Offers.state.rowsNumber"
              :server-items-length="Offers.state.total"
              :options.sync="Offers.state.pagination"
              @paginate="Offers.actions.applyOptions({ value: $event })"
              :loading="Offers.state.loading"
              :itemsNew="Offers.state.rowsNumber"
              :sortBy="Offers.state.options.sortBy"
              :sortDesc="Offers.state.options.descending"
              hide-default-footer
              v-if="Offers.state.isTable"
            >
              <template v-slot:item.thumbnail="{ item }">
                <div class="d-flex align-center tdImg">
                  <v-img v-if="item.thumbnail" :src="`${item.thumbnail}`" width="32px"></v-img>
                </div>
              </template>
              <template v-slot:item._id="{ item }">
                <v-chip
                  router
                  :to="{ name: 'Offers', params: { _offer: item._id } }"
                  small
                  transparent
                  class="font-weight-regular transparent idText"
                  @click="copyToClipboard(`${item._id}`)"
                >
                  <v-icon class="copy">{{ Icons.copyContent }}</v-icon>
                  <FormattedText :value="item._id" :num="15" class="transparent" />
                </v-chip>
              </template>
              <template v-slot:item.name="{ item }">
                <FormattedText :value="item.name" :num="55" />
              </template>
              <template v-slot:item.categories="{ item }">
                <p class="categories">
                  <FormattedText :value="categoriesCharge(item)" :num="35" />
                </p>
              </template>
              <template v-slot:item.payout="{ item }">
                <v-chip small label class="font-weight-bold payoutChip pl-1 pr-1">{{
                  item.payout | payoutField(item.currency)
                }}</v-chip>
              </template>
              <template v-slot:item.validatedStatus="{ item }">
                <v-chip
                  small
                  label
                  :style="colorStatus(item.validatedStatus)"
                  class="font-weight-bold"
                  >{{ item.validatedStatus }}</v-chip
                >
              </template>
              <template v-slot:item.details="{ item }">
                <v-chip
                  @click="selectItem(item)"
                  class="ma-2 text-capitalize"
                  depressed
                  small
                  label
                  color="white lighten-1"
                  textColor="blue"
                  style="border: 1px solid #2196f3 !important"
                  >Details <v-icon right>mdi-chevron-right</v-icon></v-chip
                >
              </template>
            </data-table>
            <data-list
              ref="list"
              :items="Offers.state.items"
              :rows-per-page="Offers.state.rowsNumberList"
              :server-items-length="Offers.state.total"
              @paginate="Offers.actions.applyOptions({ value: $event })"
              :loading="Offers.state.loading"
              :itemsNewList="Offers.state.rowsNumberList"
              :sortBy="Offers.state.options.sortBy"
              :sortDesc="Offers.state.options.descending"
              v-else
            >
              <template v-slot:default="props">
                <v-row>
                  <v-col
                    v-for="(item, index) in props.items"
                    :key="index"
                    cols="12"
                    sm="6"
                    md="6"
                    lg="4"
                    xl="3"
                  >
                    <offer-preview-grid :key="item._id" :item="item" @select="selectItem(item)" />
                  </v-col>
                </v-row>
              </template>
            </data-list>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { inject, onMounted, ref } from '@vue/composition-api';
import OfferFilters from './OfferFilters';
import DataList from '../../Components/DataList';
import DataTable from '../../Components/DataTable';
import OfferPreviewGrid from './OfferPreviewGrid.vue';
import FormattedText from '../../Components/FormattedText';

export default {
  name: 'offer-list',
  components: {
    'offer-preview-grid': OfferPreviewGrid,
    'offer-filters': OfferFilters,
    'data-list': DataList,
    'data-table': DataTable,
    FormattedText,
  },
  setup(props, context) {
    const { platform } = inject('Platform');
    const Offers = inject('Offers');
    const Categories = inject('Categories');
    const list = ref(null);
    const table = ref(null);
    const headers = [
      { text: ' ', align: 'start', value: 'thumbnail', sortable: false, width: '5%' },
      { text: 'ID', value: '_id', sortable: false, width: '15%' },
      { text: 'NAME', value: 'name', sortable: true, width: '30%' },
      { text: 'VERTICALS', value: 'categories', sortable: false, width: '20%' },
      { text: 'PAYOUT', value: 'payout', sortable: true, width: '10%' },
      { text: 'STATUS', value: 'validatedStatus', sortable: true, width: '10%' },
      { text: 'DETAILS', value: 'details', sortable: false, width: '10%' },
    ];
    const statusColors = {
      Approved: 'backgroundColor:#c0f3b6; color:#009600;',
      'Apply to run': 'backgroundColor:#c3ebff; color:#0082ff;',
      Applied: 'backgroundColor:#ffdc8b; color:#ff7300;',
      Rejected: 'backgroundColor:#e1e1e1; color:#6e6e6e;',
      Blocked: 'backgroundColor:#ffc8dc; color:#ff0000;',
      default: 'backgroundColor:#000000; color:#ffffff;',
    };
    onMounted(async () => {
      await Offers.actions.loadItems();
    });
    function resetPagination() {
      if (list.value && list.value.resetPagination) {
        list.value.resetPagination();
      }
      if (table.value && table.value.resetPagination) {
        table.value.resetPagination();
      }
    }
    const setPreSelectItemPage = () => {
      if (list.value) {
        list.value.pagination.page = Offers.state.preSelectItemPage.page;
        list.value.pagination.itemsPerPage = Offers.state.preSelectItemPage.itemsPerPage;
      }
      if (table.value) {
        table.value.pagination.page = Offers.state.preSelectItemPage.page;
        table.value.pagination.itemsPerPage = Offers.state.preSelectItemPage.itemsPerPage;
      }
    };
    const setOffersView = () => {
      if (list.value) {
        Offers.actions.setOffersView(false);
      }
      if (table.value) {
        Offers.actions.setOffersView(true);
      }
    };
    const selectItem = (item) => {
      Offers.actions.selectItem({ value: item });
      if (list.value) {
        Offers.actions.setPreSelectItemPage({
          page: list.value.pagination.page,
          itemsPerPage: list.value.pagination.itemsPerPage,
        });
      }
      if (table.value) {
        Offers.actions.setPreSelectItemPage({
          page: table.value.pagination.page,
          itemsPerPage: table.value.pagination.itemsPerPage,
        });
      }
    };

    const categoriesCharge = (item) => {
      const categories = Categories.state.items;
      let arrayCategories = [];
      for (let idNum = 0; idNum < item._categories.length; idNum++) {
        let valCate = categories.find((cat) => cat._id == item._categories[idNum]);
        if (valCate) {
          arrayCategories.push(valCate.name);
        }
      }
      return arrayCategories.join(' / ');
    };
    const copyToClipboard = (text) => {
      if (navigator.clipboard) navigator.clipboard.writeText(text);
    };

    return {
      platform,
      headers,
      Offers,
      resetPagination,
      list,
      table,
      selectItem,
      setPreSelectItemPage,
      categoriesCharge,
      setOffersView,
      copyToClipboard,
      statusColors,
    };
  },
  methods: {
    colorStatus(status) {
      return this.statusColors[status] || this.statusColors.default;
    },
  },
};
</script>
<style lang="scss">
table {
  table-layout: fixed;
  max-width: 100%;
}
</style>
